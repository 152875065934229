<router>
{
    name: 'Sales Tool Licensing',
}
</router>
<template lang="pug">
v-container
    v-row
        v-col
            Licensing

    //- v-btn(primary, @click="$auth.logout('aad')") Sign out
</template>
<script>

import Licensing from '@/components/salestool/Licensing.vue';

export default {
    meta: {
        role: 'licensing'
    },
    components: {
        Licensing
    },
    data () {
        return {
        };
    },
    methods: {

    },
    computed: {

    },
    mounted () {

    }
};
</script>

<style scoped>
</style>
